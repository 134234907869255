import Callout from "./Callout/Callout";
import styled from "styled-components";

interface Props {
  text: string;
  type: string;
  name: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  width: 100%;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabeledInput = (props: Props) => {
  return (
    <StyledContainer>
      <StyledLabel>
        <span>{props.text}</span>
        <input
          type={props.type}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
      </StyledLabel>
      {props.error && <Callout type="error">{props.error}</Callout>}
    </StyledContainer>
  );
};

export default LabeledInput;
