import { LoaderFunctionArgs } from "react-router-dom";
import { getAllQuestions, getMe, getStack, nextQuestion } from "../api";

export const loadStacks = async ({ params, context }: LoaderFunctionArgs) => {};

export const loadStack = async ({ params }: LoaderFunctionArgs) => {
  const { stackId } = params;
  if (!stackId) throw new Error("stack ID required");
  const { data: stack } = await getStack(stackId);
  const {
    data: { list: questions },
  } = await getAllQuestions(stackId);
  return { stack, questions };
};

export const loadStudyQuestion = async ({ params }: LoaderFunctionArgs) => {
  const { stackId } = params;
  if (!stackId) throw new Error("stack ID required");
  const { data: question } = await nextQuestion(stackId);

  return { question };
};

export const loadUser = async (_args: LoaderFunctionArgs) => {
  const { data: user } = await getMe();
  return { user };
};
