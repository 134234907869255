import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouteProps,
  useSearchParams,
} from "react-router-dom";
import NavBar from "../components/NavBar";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import { useMemo } from "react";
import Stacks from "../pages/Stacks";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import SignUp from "../pages/Signup";
import PasswordReset from "../pages/PasswordReset";
import QuickStart from "../pages/QuickStart";
import VerifyEmail from "../pages/VerifyEmail";
import Stack from "../pages/Stack";
import Study from "../pages/Study";
import MyAccount from "../pages/MyAccount";
import QuestionEditor from "../pages/QuestionEditor";
import { loadStack, loadStacks, loadStudyQuestion, loadUser } from "./loaders";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    --toastify-icon-color-success: #2ec4b6;
    --toastify-color-progress-success: #2ec4b6;

    --toastify-icon-color-warning: #ffb400;
    --toastify-color-progress-warning: #ffb400;

    --toastify-icon-color-error: #e63946;
    --toastify-color-progress-error: #e63946;
  }
`;

function Layout() {
  const [searchParams] = useSearchParams();

  if (searchParams.get("email_verification_success")) {
    toast("Email verified!");
  }
  return (
    <>
      <NavBar />
      <Outlet />
      <StyledToastContainer
        position="bottom-left"
        closeButton
        autoClose={3000}
      />
    </>
  );
}

const PrivateLayout: React.FC<RouteProps> = () => {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) {
    logout();
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="password-reset" element={<PasswordReset />} />
      <Route path="quick-start" element={<QuickStart />} />
      <Route element={<PrivateLayout />}>
        <Route path="verify/email/confirmed" element={<VerifyEmail />} />
        <Route path="stacks">
          <Route index element={<Stacks />} />
          <Route
            path="/stacks/:stackId"
            element={<Stack />}
            loader={loadStack}
          />
          <Route
            path="/stacks/:stackId/study"
            loader={loadStudyQuestion}
            element={<Study />}
          />
        </Route>
        <Route path="my-account" loader={loadUser} element={<MyAccount />} />
        <Route
          path="stacks/:stackId/questions/new"
          element={<QuestionEditor />}
        />
        <Route
          path="/stacks/:stackId/questions/:questionId"
          element={<QuestionEditor />}
        />
      </Route>
    </Route>
  )
);
