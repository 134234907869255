import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import styled from "styled-components";
import TextButton from "./TextButton";

import XpTicker from "./XpTicker";
import { Theme, useTheme } from "../contexts/ThemeContext";
import { Column, Row } from "./layout";
import { useResponsive } from "../contexts/ResponsiveContext";
import HamburgerMenu from "./HamburgerMenu";

interface LinkData {
  label: string;
  to: string;
  show: (authed: boolean) => boolean;
}

const links = [
  {
    label: "home.",
    to: "/",
    show: (authed: boolean) => !authed,
  },
  {
    label: "stacks.",
    to: "/stacks",
    show: (authed: boolean) => authed,
  },
  {
    label: "quick start.",
    to: "/quick-start",
    show: (_authed: boolean) => true,
  },
  {
    label: "sign up.",
    to: "/sign-up",
    show: (authed: boolean) => !authed,
  },
  {
    label: "my account.",
    to: "/my-account",
    show: (authed: boolean) => authed,
  },
  {
    label: "log in.",
    to: "/login",
    show: (authed: boolean) => !authed,
  },
];

const Links = ({ links }: { links: LinkData[] }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { isMobile } = useResponsive();

  return (
    <>
      {links.map(
        (link) =>
          link.show(isAuthenticated) && (
            <TextButton
              key={`nav-${link.label}`}
              text={link.label}
              onClick={() => navigate(link.to)}
              btnStyle={{
                fontSize: isMobile ? theme.fontSize.largeDisplay : "",
                color: isMobile ? theme.colors.light : "",
              }}
            />
          )
      )}
    </>
  );
};

const NavMenu = ({ links }: { links: LinkData[] }) => {
  const { isMobile } = useResponsive();
  const { theme } = useTheme();

  return (
    <>
      {isMobile ? (
        <HamburgerMenu>
          <Links links={links} />
        </HamburgerMenu>
      ) : (
        <Row style={{ gap: theme.spacing.padding.xl }}>
          <Links links={links} />
        </Row>
      )}
    </>
  );
};

const StyledNav = styled.nav<{ theme: Theme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 3vw;
`;

const StyledLogo = styled.div<{ theme: Theme }>`
  font-family: "Fira Sans", serif;
  text-decoration: none;
  color: ${(props) => props.theme.colors.dark};
  font-weight: 900;
  font-size: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const AuthedLayout = () => {
  const { isMobile } = useResponsive();

  return (
    <>
      <Column style={{ flex: "2", alignItems: "center" }}>
        {isMobile ? <XpTicker /> : <NavMenu links={links} />}
      </Column>
      <Column style={{ flex: "1", alignItems: "flex-end" }}>
        {isMobile ? <NavMenu links={links} /> : <XpTicker />}
      </Column>
    </>
  );
};

function NavBar() {
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();
  const { theme } = useTheme();

  return (
    <StyledNav theme={theme}>
      <Column
        style={{
          flex: "1",
        }}
      >
        <StyledLogo
          theme={theme}
          onClick={() => navigate(isAuthenticated ? "/stacks" : "/")}
        >
          pohtia.
        </StyledLogo>
      </Column>

      {isAuthenticated ? (
        <AuthedLayout />
      ) : (
        <Column style={{ alignItems: "flex-end" }}>
          <NavMenu links={links} />
        </Column>
      )}
    </StyledNav>
  );
}

export default NavBar;
