import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import styles from "./Markdown.module.scss";
import { CSSProperties } from "react";
import { Paragraph, Code, LatexBlock, Li } from "./MarkdownComponents";
import Heading from "./MarkdownComponents/Heading";
import Spacer from "./Spacer";

const Markdown = ({
  value,
  className,
  style,
}: {
  value: string;
  className?: string;
  style?: CSSProperties;
}): JSX.Element => {
  return (
    <div className={className} style={style}>
      <ReactMarkdown
        components={{
          code: (props) => <Code {...props} />,
          p: ({ node, className, ...props }) => (
            <Paragraph>{props.children}</Paragraph>
          ),
          img: ({ node, className, ...props }) => {
            return (
              <img className={styles.markdownImg} src={props.src} alt="" />
            );
          },
          h1: ({ children }) => <Heading type="xl">{children}</Heading>,
          h2: ({ children }) => <Heading type="l">{children}</Heading>,
          h3: ({ children }) => <Heading type="m">{children}</Heading>,
          hr: () => <Spacer size="1em" withLine />,
          div: (props) => {
            if (props.className === "math math-display") {
              return <LatexBlock {...props} />;
            }

            return <div {...props} />;
          },
          li: (props) => <Li {...props} />,
        }}
        children={value}
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
      />
    </div>
  );
};

export default Markdown;
