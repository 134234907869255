import { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Heading from "./Heading";
import { Column, Columns } from "./layout";
import { useAuth } from "../contexts/AuthContext";
import { Theme, useTheme } from "../contexts/ThemeContext";
import { Socket } from "phoenix";
import { getSocketToken } from "../api";

const LoadingText = () => (
  <span style={{ fontStyle: "italic", fontSize: "14px" }}>Loading...</span>
);

const TickerContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledTicker = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.dark};
  padding: 0.5em 1em;
  background-color: ${(props) => props.theme.colors.light};
  border: 3px solid ${(props) => props.theme.colors.easy};
  width: 100px;
  border-radius: 5px;
`;

const xpChangeAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    font-weight: 800;
  }
  100% {
    transform: scale(1);
    color: white;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
`;

const AnimatedXp = styled.span.withConfig({
  shouldForwardProp: (props) => !["isAnimating"].includes(props),
})<{ isAnimating: boolean }>`
  display: inline-block;
  ${({ isAnimating }) =>
    isAnimating &&
    css`
      animation: ${xpChangeAnimation} 0.5s;
    `}
`;

const XpDiff = styled.span.withConfig({
  shouldForwardProp: (props) => !["xpDiff"].includes(props),
})<{ xpDiff: number; theme: Theme }>`
  opacity: ${({ xpDiff }) => (xpDiff > 0 ? 1 : 0)};
  position: absolute;
  top: 50px;
  left: 50%;
  color: ${(props) => props.theme.colors.dark};
  font-weight: 800;
  z-index: 0;
  animation: ${({ xpDiff }) => (xpDiff > 0 ? fadeOutAnimation : "none")} 2s ease
    forwards;
`;

interface XpPayload {
  xp: number;
  current_level: number;
  next_level_xp: number;
  delta: number;
}

const Progress = styled.div<{ progress: number; theme: Theme }>`
  opacity: ${({ progress }) => (progress >= 1 ? 1 : 0)};
  position: absolute;
  display: flex;
  left: 0;
  background-color: ${({ theme }) => theme.colors.easy};
  height: 100%;
  width: ${({ progress }) => progress}%;
  border-radius: 5px;
  transition: width 1s ease;
`;

const TextContainer = styled(Columns)`
  z-index: 1;
  width: 100%;
`;

const XpTicker = () => {
  const [xp, setXp] = useState<number>(0);
  const [level, setLevel] = useState<number>(1);
  const [prevXp, setPrevXp] = useState<number>(1);
  const [xpDiff, setXpDiff] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [nextLevelXp, setNextLevelXp] = useState<number>(1);
  const { userId } = useAuth();
  const { theme } = useTheme();

  useEffect(() => {
    (async () => {
      if (!userId) return;

      const {
        data: { token },
      } = await getSocketToken(userId);

      const socketUrl =
        process.env.NODE_ENV === "development"
          ? "ws://localhost:1337/socket"
          : "wss://api.pohtia.com/socket";

      const socket = new Socket(socketUrl, {
        params: { token: token },
      });

      socket.connect();

      const channel = socket.channel(`user_xp:${userId}`);

      channel
        .join()
        .receive("ok", (resp) => {
          setLoading(false);
          setXp(resp.xp || 1);
          setLevel(resp.current_level || 1);
          setNextLevelXp(resp.next_level_xp || 1);
        })
        .receive("error", (resp) => {
          console.error("Unable to join channel", resp);
        });

      channel.on("xp_change", (event: XpPayload) => {
        console.log("Received XP grant", event);
        const { xp: payloadXp, current_level, next_level_xp, delta } = event;
        setLevel(current_level);
        setXpDiff(delta);
        setXp(payloadXp);
        setNextLevelXp(next_level_xp);
      });

      channel.onError((e) => console.error(e));

      return () => {
        console.log("!!! UNMOUNT !!!");
        channel.leave();
        socket.disconnect();
      };
    })();
  }, [userId]);

  useEffect(() => {
    if (xpDiff !== null) {
      const timer = setTimeout(() => setXpDiff(null), 2000);
      return () => clearTimeout(timer);
    }
  }, [xpDiff]);

  useEffect(() => {
    if (prevXp !== null && xp !== prevXp) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
    setPrevXp(xp);
  }, [xp, prevXp]);

  const percentage = Math.round((xp / nextLevelXp) * 100);

  return (
    <TickerContainer>
      <StyledTicker theme={theme}>
        <TextContainer>
          <Column style={{ flexBasis: "25%" }}>
            <Heading text="Lvl" type="xs" />
          </Column>
          <Column style={{ flexBasis: "50%", alignItems: "flex-end" }}>
            {loading ? (
              <LoadingText />
            ) : (
              <AnimatedXp isAnimating={isAnimating}>{level}</AnimatedXp>
            )}
          </Column>
        </TextContainer>
        <Progress progress={percentage} theme={theme} />
      </StyledTicker>

      <XpDiff xpDiff={xpDiff || 0} theme={theme}>
        +{xpDiff}
      </XpDiff>
    </TickerContainer>
  );
};

export default XpTicker;
