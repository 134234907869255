import { CSSProperties, forwardRef, Ref } from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  style?: CSSProperties;
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

const Column = forwardRef<HTMLDivElement, Props>(
  ({ children, style }, ref: Ref<HTMLDivElement>) => (
    <StyledDiv style={style} ref={ref} id="col">
      {children}
    </StyledDiv>
  )
);

export default Column;
