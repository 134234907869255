import styled from "styled-components";
import { Theme, useTheme } from "../../contexts/ThemeContext";

const StyledParagraph = styled.p<{ theme: Theme }>`
  font-weight: ${(props) => props.theme.fontSize.body};
  line-height: ${(props) => props.theme.spacing.lineHeight.normal};
`;

const Paragraph = ({ children }: JSX.IntrinsicElements["p"]) => {
  const { theme } = useTheme();

  return <StyledParagraph theme={theme}>{children}</StyledParagraph>;
};

export default Paragraph;
