import { useCallback, useState } from "react";
import { ApiResp, ReqState } from "./types";
import { toast } from "react-toastify";

export const useRequest = <T>(reqFn: (...args: any[]) => ApiResp<T>) => {
  const [loading, setLoading] = useState<boolean>(false);

  const req = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      const promise = reqFn(...args);
      const { status, data } = await toast.promise(promise, {
        pending: "Processing",
        success: "Success",
        error: "Something went wrong",
      });

      if (status < 400) {
        return data;
      }

      setLoading(false);
    },
    [reqFn]
  );

  return { request: req, loading };
};
