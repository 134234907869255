import styled from "styled-components";
import Heading from "../components/Heading";
import { useTheme } from "../contexts/ThemeContext";
import Spacer from "../components/Spacer";
import { Row } from "../components/layout";
import CommandButton from "../components/CommandButton";
import { To, useNavigate } from "react-router-dom";
import { Action, Navigation, PageProps } from "./types";
import LoadingSpinner from "../components/LoadingSpinner";
import { Space } from "lucide-react";

interface Props extends PageProps {
  title: string;
  loading?: boolean;
}

const NavigateActions = ({ navigation }: { navigation: Navigation[] }) => {
  const navigate = useNavigate();
  return (
    <Row style={{ gap: "5vw" }}>
      {navigation.map((n, idx) => (
        <CommandButton
          key={`${n.title}-${idx}`}
          command={n.command || "back"}
          text={n.title}
          onClick={() => navigate(n.to as To)}
          color="dark"
        />
      ))}
    </Row>
  );
};

const Actions = ({
  actions,
  justifyActions,
}: {
  actions: Action[];
  justifyActions?: string;
}) => {
  return (
    <span
      style={{
        display: "flex",
        gap: "2vw",
        justifyContent: justifyActions || "flex-start",
      }}
    >
      {actions.map((a) => (
        <CommandButton
          command={a.command || "add"}
          text={a.title}
          onClick={() => a.onClick()}
          color={a.color}
        />
      ))}
    </span>
  );
};

const Pagination = ({
  onNext,
  onPrev,
  nextDisabled,
  prevDisabled,
}: {
  nextDisabled: boolean;
  prevDisabled: boolean;
  onNext: () => void;
  onPrev: () => void;
}) => {
  return (
    <span style={{ display: "flex", gap: "2vw" }}>
      <CommandButton
        disabled={prevDisabled}
        command="back"
        text="Prev"
        onClick={onPrev}
      />
      <CommandButton
        disabled={nextDisabled}
        command="forward"
        text="Next"
        onClick={onNext}
      />
    </span>
  );
};

const StyledContainer = styled.div`
  /* Base styles for mobile-first */
  padding: 3vh 3vw;

  /* Small devices (portrait tablets and large phones, 576px and up) */
  @media (min-width: 576px) {
    padding: 3vh 4vw;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) {
    padding: 4vh 5vw;
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 3vh 3vw;
  }

  /* Extra Large devices (large desktops, 1200px and up) */
  @media (min-width: 1920px) {
    padding: 5vh 20vw;
  }
`;

const TitledPage = ({
  title,
  children,
  actions,
  navigation,
  pagination,
  justifyActions,
  loading = false,
}: Props) => {
  const { theme } = useTheme();
  return (
    <StyledContainer>
      {navigation && navigation.length > 0 ? (
        <>
          <NavigateActions navigation={navigation} />
          <Spacer size={"3vh"} />
        </>
      ) : null}

      <Heading text={title} type="xl" style={{}} />
      <Spacer size={theme.spacing.paragraphSpacing.small} />
      <Row style={{ justifyContent: "space-between" }}>
        {actions && actions?.length > 0 && (
          <Actions actions={actions} justifyActions={justifyActions} />
        )}
      </Row>
      <Spacer size={theme.spacing.paragraphSpacing.small} />

      {loading ? <LoadingSpinner /> : children}
      {pagination && (
        <>
          <Spacer size="1em" />
          <Row style={{ justifyContent: "center" }}>
            <Pagination {...pagination} />
          </Row>
        </>
      )}
    </StyledContainer>
  );
};

export default TitledPage;
