import styled from "styled-components";
import { useTheme, Theme, NamedColor } from "../contexts/ThemeContext";

type Command = "add" | "delete" | "back" | "forward" | "misc";

interface Props {
  text: string;
  command: Command;
  onClick: () => void;
  color?: NamedColor;
  disabled?: boolean;
}

interface CircleProps {
  theme: Theme;
  size?: number; // Size of the circle in pixels
  backgroundColor?: string; // Background color of the circle
  symbolColor?: string; // Color of the symbol (plus, minus, or dot)
}

interface SymbolProps {
  symbolColor: string; // Color of the symbol
}

const Circle = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["backgroundColor", "symbolColor"].includes(prop),
})<CircleProps>`
  width: ${({ size }) => size || 20}px;
  height: ${({ size }) => size || 20}px;
  background-color: ${({ backgroundColor, theme }) => {
    switch (backgroundColor) {
      case "secondary":
        return theme.colors.secondaryColor;
      case "warning":
        return theme.colors.warning;
      case "highlight":
        return theme.colors.highlight;
      case "easy":
        return theme.colors.easy;
      case "moderate":
        return theme.colors.moderate;
      case "difficult":
        return theme.colors.difficult;
      default:
        return theme.colors.dark;
    }
  }};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Horizontal = styled.div.withConfig({
  shouldForwardProp: (prop) => !["symbolColor"].includes(prop),
})<SymbolProps>`
  position: absolute;
  width: 60%;
  height: 10%;
  background-color: ${({ symbolColor }) => symbolColor};
`;

const Vertical = styled.div.withConfig({
  shouldForwardProp: (prop) => !["symbolColor"].includes(prop),
})<SymbolProps>`
  position: absolute;
  width: 10%;
  height: 60%;
  background-color: ${({ symbolColor }) => symbolColor};
  display: block};
`;

const Dot = styled.div.withConfig({
  shouldForwardProp: (prop) => !["symbolColor"].includes(prop),
})<SymbolProps>`
  width: 6px;
  height: 6px;
  background-color: ${({ symbolColor }) => symbolColor};
  border-radius: 50%;
  display: block;
`;

const LessThan = styled.div.withConfig({
  shouldForwardProp: (prop) => !["symbolColor"].includes(prop),
})<SymbolProps>`
  position: absolute;
  width: 5px;
  height: 5px;
  left: 8px;
  border-left: 2px solid ${({ symbolColor }) => symbolColor};
  border-bottom: 2px solid ${({ symbolColor }) => symbolColor};
  transform: rotate(45deg);
  display: block;
`;

const GreaterThan = styled.div.withConfig({
  shouldForwardProp: (prop) => !["symbolColor"].includes(prop),
})<SymbolProps>`
  position: absolute;
  width: 5px;
  height: 5px;
  left: 5px;
  border-left: 2px solid ${({ symbolColor }) => symbolColor};
  border-bottom: 2px solid ${({ symbolColor }) => symbolColor};
  transform: rotate(225deg);
  display: block;
`;

const calcSymbol = (symbolColor: string, command: Command) => {
  switch (command) {
    case "add":
      return (
        <>
          <Horizontal symbolColor={symbolColor} />
          <Vertical symbolColor={symbolColor} />
        </>
      );

    case "delete":
      return <Horizontal symbolColor={symbolColor} />;

    case "back":
      return <LessThan symbolColor={symbolColor} />;

    case "forward":
      return <GreaterThan symbolColor={symbolColor} />;

    case "misc":
      return <Dot symbolColor={symbolColor} />;
  }
};

const SymbolCircle = ({
  size = 20,
  backgroundColor,
  symbolColor = "#fff",
  command,
  theme,
}: CircleProps & { command: Command }) => {
  return (
    <Circle size={size} backgroundColor={backgroundColor} theme={theme}>
      {calcSymbol(symbolColor, command)}
    </Circle>
  );
};

const ButtonWrapper = styled.button<{ theme: Theme }>`
  border: none;
  appearance: none;
  background: none;
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Spacing between the circle and the text */
  cursor: pointer;
  position: relative;

  &:not(:disabled):hover::after {
    transform: scaleX(1);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 28px;
    width: calc(100% - 28px);
    height: 2px;
    background-color: ${(props) => props.theme.colors.dark};
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const ButtonText = styled.span<{ theme: Theme }>`
  font-size: ${(props) => props.theme.fontSize.subHeading};
  font-weight: ${(props) => props.theme.type.regular};
  color: ${(props) => props.theme.colors.dark};
`;

const CommandButton = ({ text, command, onClick, color, disabled }: Props) => {
  const { theme } = useTheme();

  return (
    <ButtonWrapper onClick={onClick} theme={theme} disabled={disabled}>
      <SymbolCircle
        backgroundColor={color}
        symbolColor={theme.colors.light}
        command={command}
        theme={theme}
      />
      <ButtonText theme={theme}>{text}</ButtonText>
    </ButtonWrapper>
  );
};

export default CommandButton;
