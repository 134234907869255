import React, { useContext } from "react";

const TYPE_WEIGHTS = {
  bold: 800,
  semiBold: 700,
  regular: 600,
  semiLight: 500,
  light: 400,
  extraLight: 300,
};

const COLORS = {
  blue: "#cbd9ea",
  red: "#f25b44",
  navy: "#333c48",
  paleBlue: "#ced8f2",
  charcoal: "#1b1b1e",
  crimson: "#E63946",
  softWhite: "#F1FAEE",
  mint: "#A8DADC",
  steelBlue: "#457B9D",
  gunMetal: "#343A40",
  vibrantGreen: "#2EC4B6", // Added for 'easy'
  amber: "#FFB400", // Added for 'moderate'
  deepPurple: "#5A189A", // Added for 'difficult'
};

const THEME_COLORS = {
  primaryColor: COLORS.crimson,
  secondaryColor: COLORS.crimson,
  warning: COLORS.crimson,
  muted: COLORS.navy,
  pale: COLORS.paleBlue,
  dark: COLORS.charcoal,
  light: "white",
  highlight: COLORS.mint,
  easy: COLORS.vibrantGreen,
  moderate: COLORS.amber,
  difficult: COLORS.deepPurple,
};

export type NamedColor = keyof typeof THEME_COLORS;

const SPACING = {
  lineHeight: {
    compact: "1.2",
    normal: "1.5",
    loose: "2",
  },
  letterSpacing: {
    tight: "-0.05em",
    normal: "0",
    wide: "0.1em",
  },
  padding: {
    xs: "clamp(4px, 1vw, 8px)",
    s: "clamp(8px, 2vw, 16px)",
    m: "clamp(16px, 3vw, 24px)",
    l: "clamp(24px, 4vw, 32px)",
    xl: "clamp(32px, 5vw, 48px)",
  },

  paragraphSpacing: {
    small: "2vh",
    normal: "3vh",
    large: "5vh",
  },
  indentation: "1em",
};

const FONT_SIZES = {
  small: "clamp(10px, 2vw, 12px)",
  body: "clamp(14px, 2.5vw, 16px)",
  subHeading: "clamp(14px, 3vw, 18px)",
  heading: "clamp(20px, 4vw, 24px)",
  display: "clamp(28px, 5vw, 32px)",
  largeDisplay: "clamp(36px, 6vw, 42px)",
};

export type Theme = {
  colors: {
    primaryColor: string;
    secondaryColor: string;
    warning: string;
    muted: string;
    pale: string;
    dark: string;
    light: string;
    highlight: string;
    easy: string;
    moderate: string;
    difficult: string;
  };
  type: {
    bold: number;
    semiBold: number;
    regular: number;
    semiLight: number;
    light: number;
    extraLight: number;
  };
  spacing: {
    lineHeight: {
      compact: string;
      normal: string;
      loose: string;
    };
    letterSpacing: {
      tight: string;
      normal: string;
      wide: string;
    };
    paragraphSpacing: {
      small: string;
      normal: string;
      large: string;
    };
    padding: {
      xs: string;
      s: string;
      m: string;
      l: string;
      xl: string;
    };
    indentation: string;
  };
  fontSize: {
    small: string;
    body: string;
    subHeading: string;
    heading: string;
    display: string;
    largeDisplay: string;
  };
};

type ThemeContextType = {
  theme: Theme;
};

const ThemeContext = React.createContext<ThemeContextType>({
  theme: {
    colors: {
      ...THEME_COLORS,
    },
    type: {
      ...TYPE_WEIGHTS,
    },
    spacing: {
      ...SPACING,
    },
    fontSize: {
      ...FONT_SIZES,
    },
  },
});

export const ThemeProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <ThemeContext.Provider
      value={{
        theme: {
          colors: {
            ...THEME_COLORS,
          },
          type: {
            ...TYPE_WEIGHTS,
          },
          spacing: {
            ...SPACING,
          },
          fontSize: {
            ...FONT_SIZES,
          },
        },
      }}
      {...props}
    />
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
