import { useEffect, useState } from "react";

import {
  createQuestion,
  getQuestion,
  updateQuestion,
  deleteQuestion,
  createImage,
} from "../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Markdown from "../components/Markdown";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import Spacer from "../components/Spacer";
import { Column, Grid, Row } from "../components/layout";
import TextInput from "../components/TextInput";
import Heading from "../components/Heading";
import StandoutCard from "../components/StandoutCard";
import TitledPage from "../layouts/TitledPage";
import IconButton from "../components/IconButton";
import { Save } from "lucide-react";
import { useResponsive } from "../contexts/ResponsiveContext";
import { useRequest } from "../api/useRequest";

const handlePaste = async (
  userId: string | null,
  event: React.ClipboardEvent<HTMLTextAreaElement>,
  setLoading: (val: boolean) => void,
  onChange: React.Dispatch<React.SetStateAction<string>>
) => {
  const items = event.clipboardData?.items;
  if (!items || !userId) return;
  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf("image") !== -1) {
      const blob = items[i].getAsFile();
      if (!blob) return;

      const reader = new FileReader();
      reader.onload = async (event) => {
        const base64String = event.target?.result as string;
        if (base64String) {
          setLoading(true);

          const { data } = await createImage(userId, base64String);
          const domain =
            process.env.NODE_ENV === "development"
              ? "http://localhost:1337"
              : "https://api.pohtia.com";

          const imageMarkdown = `![Alt text](${domain}/api/images/${data.filename})`;
          onChange((prevMarkdown) => prevMarkdown + imageMarkdown);
          setLoading(false);
        }
      };

      reader.readAsDataURL(blob);
    }
  }
};

const EditSection = ({
  title,
  value,
  onChange,
}: {
  title: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { userId } = useAuth();

  return (
    <>
      <Grid gap="1em" columns="50% 50%">
        <Column>
          <Heading text={title} />
          <Spacer size="0.5em" />
          <TextInput
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onPaste={(e, setLoading) =>
              handlePaste(userId, e, setLoading, onChange)
            }
            placeholder={`Type you ${title.toLowerCase()} markdown`}
            style={{ height: "100%", minHeight: "8em" }}
          />
        </Column>
        <Column>
          <Heading text="Preview" />
          <Spacer size="0.5em" />
          <StandoutCard
            shortShadow
            border
            style={{ height: "100%", padding: "1% 3%" }}
          >
            <Markdown value={value} style={{ backgroundColor: "white" }} />
          </StandoutCard>
        </Column>
      </Grid>
    </>
  );
};

const QuestionEditor = () => {
  const [question, setQuestion] = useState<string>("## My question");
  useState<boolean>(false);
  const [answer, setAnswer] = useState<string>("## My answer");
  const { stackId, questionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const stackName = location.state?.stackName;
  const { isMobile } = useResponsive();
  const { request: requestQuestion, loading } = useRequest(getQuestion);
  const { request: saveQuestion, loading: saveProcessing } =
    useRequest(updateQuestion);

  useEffect(() => {
    if (!stackId || !questionId) return;
    (async () => {
      const data = await requestQuestion(stackId, questionId);

      if (data) {
        setQuestion(data.question);
        setAnswer(data.answer);
      }
    })();
  }, [questionId, requestQuestion, stackId]);

  const handleSave = async () => {
    if (!stackId) return;

    if (!question || !answer) {
      toast("Must have question and answer");
      return;
    }

    if (questionId) {
      await saveQuestion(questionId, stackId, question, answer);
    } else {
      const { status } = await createQuestion(stackId, question, answer);

      if (status === 200) {
        navigate(`/stacks/${stackId}`);
        toast("Question created!");
      }
    }
  };

  const handleDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete this question?");

    if (confirmed && stackId && questionId) {
      const { status } = await deleteQuestion(stackId, questionId);

      if (status === 200) {
        toast("Question deleted");
        navigate(`/stacks/${stackId}`);
      }
    }
  };

  return (
    <TitledPage
      title={stackName}
      navigation={[
        {
          title: "Back",
          to: -1,
        },
      ]}
      actions={
        questionId
          ? [
              {
                title: "Delete question",
                command: "delete",
                onClick: handleDelete,
                color: "warning",
              },
            ]
          : []
      }
    >
      <StandoutCard border noShadow style={{ paddingBottom: "2.5em" }}>
        <EditSection title="Question" value={question} onChange={setQuestion} />
        <Spacer size="2em" />
        <EditSection title="Answer" value={answer} onChange={setAnswer} />
      </StandoutCard>
      <Spacer size="1em" />
      <Row style={{ justifyContent: "flex-end" }}>
        <IconButton
          onClick={handleSave}
          iconColor="easy"
          icon={Save}
          text="Save"
          style={{ flex: isMobile ? "1 1 100%" : "0 0 25%" }}
        />
      </Row>
    </TitledPage>
  );
};

export default QuestionEditor;
