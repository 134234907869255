import styled from "styled-components";
import { MOBILE_MAX_WIDTH } from "../../global";

interface GridProps {
  columns?: string; // Defines grid-template-columns (e.g., "1fr 2fr" or "repeat(3, 1fr)")
  rows?: string; // Defines grid-template-rows (e.g., "1fr 100px")
  gap?: string; // Defines the gap between grid items (e.g., "10px" or "1rem")
  children: JSX.Element[] | JSX.Element;
  className?: string; // Additional CSS classes
}

const StyledGrid = styled.div<{
  columns?: string;
  rows?: string;
  gap?: string;
  singleChild?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ columns, singleChild }) =>
    singleChild ? "1fr" : columns || "1fr"};
  grid-template-rows: ${({ rows }) => rows || "auto"};
  gap: ${({ gap }) => gap || "1rem"};
  width: 100%;

  ${({ singleChild }) =>
    singleChild &&
    `
    & > * {
      grid-column: span 1;
      max-width: 33%;
    }
  `}

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    grid-template-columns: 1fr;
    max-width: none;

    ${({ singleChild }) =>
      singleChild &&
      `
      & > * {
        max-width: none;
      }
    `}
  }
`;

const Grid = ({ columns, rows, gap, children, className }: GridProps) => {
  // Determine if there's only one child
  const isSingleChild = !Array.isArray(children) || children.length === 1;

  return (
    <StyledGrid
      columns={columns}
      rows={rows}
      gap={gap}
      className={className}
      singleChild={isSingleChild}
    >
      {children}
    </StyledGrid>
  );
};

export default Grid;
