import { useEffect, useRef, useState } from "react";
import { Question, Stack as StackType } from "../types";
import {
  deleteStack,
  getAllQuestions,
  getStack,
  searchQuestions,
} from "../api";
import {
  useLoaderData,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import QuestionCard from "../components/QuestionCard";
import Input from "../components/Input";
import { useTheme } from "../contexts/ThemeContext";
import Loading from "../components/Loading";
import { debounce } from "lodash";
import TitledPage from "../layouts/TitledPage";
import { Column, Row } from "../components/layout";

import { Action } from "../layouts/types";
import Heading from "../components/Heading";
import Spacer from "../components/Spacer";

const EmptyState = () => {
  return (
    <Row
      style={{ justifyContent: "center", fontStyle: "italic", padding: "1em" }}
    >
      <Heading text="No questions" type="l" />
    </Row>
  );
};

const Stack = () => {
  const { stackId } = useParams();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const pageParam = params.get("page");
  const page = Number(pageParam);

  const { stack, questions: initQuestions } = useLoaderData() as {
    stack: StackType;
    questions: Question[];
  };
  const [questions, setQuestions] = useState<Question[]>(initQuestions);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { theme } = useTheme();

  useEffect(() => {
    (async () => {
      if (!stackId) return;

      const {
        data: { list: questions },
      } = await getAllQuestions(stackId, { page: page });
      setQuestions(questions);
    })();
  }, [page, stackId]);

  useEffect(() => {
    (async () => {
      if (!stackId) return;

      const searchTerms = searchTerm.split(" ").filter((t) => t !== "");

      const {
        data: { list: questions },
      } = await getAllQuestions(stackId, { searchTerms });
      setQuestions(questions);
    })();
  }, [searchTerm, stackId]);

  if (!stack || !stackId) return <Loading />;

  return (
    <TitledPage
      title={stack.name}
      navigation={[
        {
          title: "All stacks",
          command: "back",
          to: "/",
        },
      ]}
      actions={[
        ...(stack.number_of_questions > 0
          ? [
              {
                title: "Study",
                disabled: stack.number_of_questions === 0,
                command: "misc",
                onClick: () => navigate(`/stacks/${stackId}/study`),
                color: "easy",
              } as Action,
            ]
          : []),
        {
          title: "Add question",
          onClick: () => {
            navigate(`/stacks/${stackId}/questions/new`, {
              state: { stackName: stack.name },
            });
          },
          color: "moderate",
        },
        {
          title: "Delete stack",
          command: "delete",
          onClick: () => {
            if (!stack) return;
            // eslint-disable-next-line no-restricted-globals
            const confirmed = confirm(
              "Are you sure you want to delete this stack and all its questions?"
            );

            if (confirmed) {
              deleteStack(String(stack.id));
              navigate("/");
            }
          },
          color: "difficult",
        },
      ]}
      pagination={{
        prevDisabled: page <= 0,
        nextDisabled: questions.length < 12,
        onNext: () => {
          navigate(`/stacks/${stackId}?page=${page ? page + 1 : 1}`);
        },
        onPrev: () => {
          navigate(`/stacks/${stackId}?page=${page ? page - 1 : 0}`);
        },
      }}
    >
      <Row>
        <Input
          placeHolder="Search questions"
          onChange={debounce(setSearchTerm, 300)}
          style={{ border: `2px solid ${theme.colors.dark}` }}
        />
      </Row>
      <Spacer size="1em" />
      <Column style={{ gap: "1em" }}>
        {questions.length > 0 ? (
          questions.map(({ id, question, answer }) => (
            <QuestionCard
              key={id}
              stackId={stackId || ""}
              questionId={id}
              question={question}
              answer={answer}
              stackName={stack.name}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </Column>
    </TitledPage>
  );
};

export default Stack;
