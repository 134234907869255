import { useCallback, useEffect, useState } from "react";
import { Question } from "../types";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { nextQuestion, reportAnswer, resetTimers } from "../api";
import Markdown from "../components/Markdown";
import Button from "../components/Button";
import StandoutCard from "../components/StandoutCard";
import Heading from "../components/Heading";
import Spacer from "../components/Spacer";
import LoadingSpinner from "../components/LoadingSpinner";
import { Column, Columns, Row } from "../components/layout";
import CommandButton from "../components/CommandButton";
import TitledPage from "../layouts/TitledPage";
import { useTheme } from "../contexts/ThemeContext";

const Card = ({ value }: { value: string }) => {
  return (
    <StandoutCard border>
      <Markdown value={value} />
    </StandoutCard>
  );
};

const EmptyState = ({
  stackId,
  loadNextQuestion,
}: {
  stackId: string;
  loadNextQuestion: () => void;
}) => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  return (
    <>
      <StandoutCard border>
        <Row style={{ justifyContent: "center", padding: "1em" }}>
          <Heading text="Looks like you're all done!" />
        </Row>
      </StandoutCard>
      <Spacer size="2em" />
      <Column style={{ alignItems: "center" }}>
        <Spacer size="1em" />
        <Columns gap={theme.spacing.padding.xl}>
          <CommandButton
            text="Back to questions"
            onClick={() => navigate(`/stacks/${stackId}`)}
            command={"back"}
            color="difficult"
          />
          <CommandButton
            text="Check for new questions"
            onClick={() => loadNextQuestion()}
            command={"misc"}
            color="moderate"
          />
          <CommandButton
            text="Reset question timers and retry"
            onClick={async () => {
              const { status } = await resetTimers(stackId);
              if (status === 200) {
                loadNextQuestion();
              }
            }}
            command={"add"}
            color="easy"
          />
        </Columns>
      </Column>
    </>
  );
};

const StudyView = ({
  stackId,
  question,
  loading,
  loadNextQuestion,
}: {
  stackId: string;
  question: Question;
  loading: boolean;
  loadNextQuestion: () => void;
}) => {
  const { theme } = useTheme();
  const [revealed, setRevealed] = useState<boolean>(false);

  useEffect(() => {
    if (revealed) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [revealed]);

  const reportResult = useCallback(
    async (result: "easy" | "moderate" | "difficult") => {
      await reportAnswer(stackId, String(question.id), result);
      setRevealed(false);
      loadNextQuestion();
    },
    [loadNextQuestion, question.id, stackId]
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StudyQuestion question={question} revealed={revealed} />
      <Spacer size="2em" />
      <Row style={{ justifyContent: "center" }}>
        {revealed ? (
          <Column style={{ alignItems: "center" }}>
            <Row>
              <Heading text="How was the question?" />
            </Row>
            <Spacer size="1em" />
            <Columns gap={theme.spacing.padding.xl}>
              <CommandButton
                text="easy"
                onClick={() => reportResult("easy")}
                command={"forward"}
                color="easy"
              />
              <CommandButton
                text="moderate"
                onClick={() => reportResult("moderate")}
                command={"forward"}
                color="moderate"
              />
              <CommandButton
                text="difficult"
                onClick={() => reportResult("difficult")}
                command={"forward"}
                color="difficult"
              />
            </Columns>
          </Column>
        ) : (
          <Column style={{ flex: "0 0 40%" }}>
            <Button
              text="Show answer"
              color="red"
              onClick={() => setRevealed(true)}
              padding="s"
            />
          </Column>
        )}
      </Row>
    </>
  );
};

const StudyQuestion = ({
  question,
  revealed,
}: {
  question: Question;
  revealed: boolean;
}) => {
  return (
    <>
      <Card value={question.question} />
      <section style={{ display: revealed ? "block" : "none" }}>
        <Spacer size="1em" />
        <Card value={question.answer} />
      </section>
    </>
  );
};

const Study = (): JSX.Element => {
  const { question: initQuestion } = useLoaderData() as {
    question: Question;
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState<Question>(initQuestion);
  const { stackId } = useParams();
  const location = useLocation();
  const stackName = location.state?.stackName;

  if (!stackId) throw new Error("no stack ID");

  const loadNextQuestion = useCallback(async () => {
    setLoading(true);
    const { data } = await nextQuestion(stackId);

    setQuestion(data);
    setLoading(false);
  }, [stackId]);

  return (
    <TitledPage
      title={stackName}
      navigation={[
        {
          title: "Back",
          to: -1,
        },
      ]}
    >
      {question ? (
        <StudyView
          stackId={stackId}
          question={question}
          loading={loading}
          loadNextQuestion={loadNextQuestion}
        />
      ) : (
        <EmptyState stackId={stackId} loadNextQuestion={loadNextQuestion} />
      )}
    </TitledPage>
  );
};

export default Study;
