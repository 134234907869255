import { CSSProperties } from "react";
import styled from "styled-components";

const StyledDiv = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row
  gap: ${({ gap }) => gap || "0"};
`;

const Row = ({
  children,
  style,
  gap,
  ...props
}: {
  children: React.ReactNode;
  gap?: string;
  style?: CSSProperties;
}) => (
  <StyledDiv style={style} gap={gap} {...props}>
    {children}
  </StyledDiv>
);

export default Row;
