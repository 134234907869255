import styles from "./QuickStart.module.scss";
import Spacer from "../components/Spacer";
import StandoutCard from "../components/StandoutCard";
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";
import { Column, Columns, Grid, Row } from "../components/layout";
import styled from "styled-components";
import { NamedColor, Theme, useTheme } from "../contexts/ThemeContext";
import Callout from "../components/Callout";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

const StyledSmallStandOut = styled.div<{ theme: Theme; color: NamedColor }>`
  padding: ${({ theme }) => theme.spacing.indentation};
  color: white;
  background-color: ${({ theme, color }) => theme.colors[color]};
`;
const PaddedSection = styled.section`
  padding: 4vh 5vw;
`;

const DetailCardContainer = styled(Row)`
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
`;

const DetailCard = styled(StandoutCard)<{
  color: string;
  basis: string;
}>`
  display: flex;
  flex: 1 1 ${({ basis }) => basis};
  flex-direction: column;
  height: min-content;
  min-width: 250px;
`;

const DetailHeading = styled(Heading)`
  margin-bottom: 0.75em;
`;

const StyledUl = styled.ul`
  font-family: "Roboto Mono";
  list-style: none;
  margin: 0;
  padding: 1em;
`;

const StyledLi = styled.li`
  position: relative;
  margin-bottom: 1.5em;
  &::before {
    content: "-"; /* Insert a dash before each item */
    position: absolute;
    left: -1em; /* Align the dash to the left */
  }
`;

const QuickStart = () => {
  const { theme } = useTheme();
  return (
    <PaddedSection>
      <StyledSmallStandOut theme={theme} color="dark">
        <Heading text="Quick start" type="l" />
        <Paragraph>
          Get up and running with the app by mastering its two key concepts:{" "}
          <Callout>cards</Callout> and <Callout>stacks</Callout>.
        </Paragraph>
      </StyledSmallStandOut>
      <Spacer size="1em" />
      <DetailCardContainer>
        <DetailCard color="light" basis="20%">
          <DetailHeading text="1. Sign Up and Log In" type="l" />
          <Paragraph>
            Start by creating an account, verifying your email, and logging in
            to access all features.
          </Paragraph>
        </DetailCard>

        <DetailCard color="light" basis="60%">
          <DetailHeading text="2. Create a stack" type="l" />
          <Paragraph>
            While a default stack called <Callout>My Stack</Callout> is already
            available, you can create your own custom stacks to organize your
            study materials.
          </Paragraph>
          <StyledUl>
            <StyledLi>
              Click the <Callout>Add a Stack</Callout> button.
            </StyledLi>
            <StyledLi>
              Name your stack based on the subject or topic you want to study.
            </StyledLi>

            <StyledLi>
              Save your stack, and it will appear in your main stack list for
              easy access.
            </StyledLi>
          </StyledUl>
        </DetailCard>

        <DetailCard color="light" basis="60%">
          <DetailHeading text="3. Add Cards to a Stack" type="l" />
          <StyledUl>
            <StyledLi>
              Click <Callout>View</Callout> to open the stack you want to add
              cards to.
            </StyledLi>
            <StyledLi>
              Inside the stack, click the <Callout>Add Card</Callout> button.
            </StyledLi>
            <StyledLi>
              <Paragraph>
                Fill in the question and answer fields for your card.
              </Paragraph>
              Both fields support <Callout>Markdown</Callout>,{" "}
              <Callout>LaTeX</Callout>, and <Callout>code blocks</Callout>,
              giving you the flexibility to format your content with ease.
            </StyledLi>
            <StyledLi>All standard Markdown syntax is supported.</StyledLi>
          </StyledUl>

          <Columns gap="1em" style={{ flexWrap: "wrap" }}>
            <Column style={{ flex: "1 1 35%" }}>
              {" "}
              <StyledUl style={{ paddingTop: 0 }}>
                <StyledLi>
                  To write inline LaTeX math notation place your markup between
                  two dollar signs:{" "}
                  <span className={styles.inlineFormat}>$</span>
                </StyledLi>
                <StyledLi>
                  To write a LaTeX math notation block place your markup between
                  two double dollar signs:{" "}
                  <span className={styles.inlineFormat}>$$</span>
                </StyledLi>
                <StyledLi>
                  To write inline code place your markup between two backticks:
                  <span className={styles.inlineFormat}>`</span>
                </StyledLi>

                <StyledLi>
                  To write a code block place your markup between three
                  backticks: <span className={styles.inlineFormat}>```</span>
                </StyledLi>

                <StyledLi>
                  You can add as many cards as you want to the stack by
                  repeating this process.
                </StyledLi>
              </StyledUl>
            </Column>

            <Column style={{ flex: "1 1 50%", minWidth: "60%" }}>
              <div className={styles.dummyMarkdown}>
                <Paragraph>Inline LaTeX example: {`$\\frac{1}{2}$`}</Paragraph>
                <Paragraph>---</Paragraph>
                <Paragraph>Block LaTex example:</Paragraph>
                <Paragraph>{`$$`}</Paragraph>
                <Paragraph>{`\\frac{\\partial u}{\\partial t} = h^2 \\left( \\frac{\\partial^2 u}{\\partial x^2} + \\frac{\\partial^2 u}{\\partial y^2} + \\frac{\\partial^2 u}{\\partial z^2} \\right)`}</Paragraph>
                <Paragraph>{`$$`}</Paragraph>
                <Paragraph>---</Paragraph>
                <Paragraph>Inline code example: {`\`a + b = c\``}</Paragraph>
                <Paragraph>---</Paragraph>
                <Paragraph>Code block example:</Paragraph>
                <Paragraph>{"```py"}</Paragraph>
                <Paragraph>{"def my_func():"}</Paragraph>
                <Paragraph className={styles.indent}>
                  {"  a**2 + b**2 = c**2"}
                </Paragraph>
                <Paragraph>{"```"}</Paragraph>
              </div>
            </Column>
          </Columns>
        </DetailCard>

        <DetailCard color="light" basis="20%">
          <DetailHeading text="4. Study Your Cards" type="l" />
          <Paragraph>
            Click the <Callout>Study</Callout> button, available on both the
            list view and the stack page. This will present your cards randomly,
            one at a time.
          </Paragraph>
          <Paragraph>
            For each card, you’ll see the question. Take a moment to write down
            your answer, say it out loud, or think about it in your head.
          </Paragraph>
          <Paragraph>
            <Callout>Repeat this process as often as possible</Callout>.
            Consistent, casual practice is key to improving your long-term
            memory retention.
          </Paragraph>
        </DetailCard>
      </DetailCardContainer>
    </PaddedSection>
  );
};

export default QuickStart;
