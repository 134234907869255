import Spacer from "../components/Spacer";
import Markdown from "../components/Markdown";
import { useState } from "react";
import StandoutCard from "../components/StandoutCard";
import TextInput from "../components/TextInput";
import Column from "../components/layout/Column";
import Paragraph from "../components/Paragraph";
import Heading from "../components/Heading";
import Callout from "../components/Callout/Callout";
import styled from "styled-components";
import { Navigate, useNavigate } from "react-router-dom";
import { NamedColor, Theme, useTheme } from "../contexts/ThemeContext";
import { Columns, Row } from "../components/layout";
import SubHeading from "../components/SubHeading";
import { createTmpImage } from "../api";
import IconButton from "../components/IconButton";
import {
  Braces,
  Image as ImageIcon,
  CircleArrowRight,
  Heading1,
  Sigma,
  SquarePen,
  Brain,
  Sparkles,
} from "lucide-react";
import { useResponsive } from "../contexts/ResponsiveContext";
import { useAuth } from "../contexts/AuthContext";

const PaddedSection = styled.section`
  padding: 4vh 5vw;
`;

const StyledSmallStandOut = styled.div<{ theme: Theme; color: NamedColor }>`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.indentation};
  color: white;
  background-color: ${({ theme, color }) => theme.colors[color]};
`;

const DetailCardContainer = styled(Row)`
  gap: 2em;
  flex-wrap: wrap;
`;

const DetailCard = styled(StandoutCard)<{ color: string; basis: string }>`
  display: flex;
  flex: 1 1 ${({ basis }) => basis};
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 300px;
`;

const DetailHeading = styled(Heading)`
  margin: 0.75em 0 1em 0;
`;

const TryItExample = () => {
  const [raw, setRaw] = useState<string>(
    `## Change me!

---

### Math example

$$
f(x) = \\frac{1}{2\\pi i} \\oint_{\\gamma} \\frac{e^z}{z^2 + 1} \\, dz
$$

---

### Code example

\`\`\`py
from math import sqrt

def hypotenuse(a, b):
  c = sqrt(a**2 + b**2)
  return c
\`\`\`
---

### Paste your image below

Paste here!
`
  );

  const { theme } = useTheme();

  const onPaste = async (
    event: React.ClipboardEvent<HTMLTextAreaElement>,
    setLoading: (val: boolean) => void
  ) => {
    const items = event.clipboardData?.items;
    if (!items) return;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        if (!blob) return;

        const reader = new FileReader();
        reader.onload = async (event) => {
          const base64String = event.target?.result as string;
          if (base64String) {
            setLoading(true);

            const { data } = await createTmpImage(base64String);
            const domain =
              process.env.NODE_ENV === "development"
                ? "http://localhost:1337"
                : "https://api.pohtia.com";

            const imageMarkdown = `![Alt text](${domain}/api/images/tmp/${data.filename})`;
            setRaw((prevMarkdown) => prevMarkdown + imageMarkdown);
            setLoading(false);
          }
        };

        reader.readAsDataURL(blob);
      }
    }
  };

  return (
    <PaddedSection style={{ backgroundColor: "rgba(255, 180, 0, 0.5)" }}>
      <StyledSmallStandOut theme={theme} color="easy">
        <Heading text="TRY IT" type="l" />
        <SubHeading text="edit the example below" />
      </StyledSmallStandOut>
      <Spacer size="0.75em" />
      <Columns gap={theme.spacing.indentation} style={{ flexWrap: "wrap" }}>
        <Column
          style={{
            flexBasis: "calc(50% - 1em)",
            boxSizing: "border-box",
          }}
        >
          <TextInput
            value={raw}
            onChange={(e) => setRaw(e.target.value)}
            style={{ height: "100%", minHeight: "18em" }}
            onPaste={onPaste}
          />
        </Column>
        <Column
          style={{
            flexBasis: "calc(50% - 1em)",
            boxSizing: "border-box",
          }}
        >
          <StandoutCard style={{ padding: "2%" }}>
            <Markdown
              value={raw}
              style={{
                backgroundColor: "white",
                padding: theme.spacing.indentation,
              }}
            />
          </StandoutCard>
        </Column>
      </Columns>
    </PaddedSection>
  );
};

const HeroSection = (): JSX.Element => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { isMobile } = useResponsive();

  const Bold = styled.span`
    font-weight: ${theme.type.bold};
  `;

  const StyledImg = styled.div`
    display: flex;
    height: 100%;
    background-clip: padding-box;
    background-image: url("clutter.png");
    background-size: fit;
    background-position: center;
  `;

  if (isMobile) {
    return (
      <>
        <Row style={{ height: "40vh", overflow: "hidden" }}>
          <img
            src="clutter.png"
            alt="hero-image"
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              transform: "scale(1.5)",
              height: "auto",
            }}
          />
        </Row>
        <StandoutCard noShadow>
          <Column>
            <Heading
              style={{ wordWrap: "normal" }}
              text="Flashcards with First-Class Support for Code, Math, and Markdown"
              type="m"
            />
            <Spacer size="0.5em" />
            <Paragraph>
              Elevate your learning and boost retention with standout features
              like <Bold>seamless support for code snippets</Bold>,{" "}
              <Bold>LaTeX for equations</Bold>,
              <Bold>Markdown for formatting</Bold>,{" "}
              <Bold>and hassle-free image hosting</Bold>.
            </Paragraph>

            <Spacer size="1em" />
            <IconButton
              padding="m"
              color="warning"
              icon={CircleArrowRight}
              iconColor="light"
              textColor="light"
              text="Sign up for free"
              onClick={() => navigate("/sign-up")}
              noBorder
            />
          </Column>
        </StandoutCard>
      </>
    );
  }

  return (
    <PaddedSection>
      <Columns style={{ boxShadow: "6px 6px 2px 1px rgba(0, 0, 0, 0.2)" }}>
        <Column style={{ flex: "0 0 35%" }}>
          <StandoutCard noShadow>
            <Column>
              <Heading
                style={{ wordWrap: "normal", paddingRight: "2vw" }}
                text="Flashcards with First-Class Support for Code, Math, and Markdown"
                type="xl"
              />
              <Spacer size="1em" />
              <Paragraph>
                Elevate your learning and boost retention with standout features
                like <Callout>seamless support for code snippets</Callout>,{" "}
                <Callout>LaTeX for equations</Callout>,{" "}
                <Callout>Markdown for formatting</Callout>,{" "}
                <Callout>and hassle-free image hosting</Callout>.
              </Paragraph>

              <Spacer size="1em" />
              <IconButton
                padding="s"
                color="warning"
                icon={CircleArrowRight}
                iconColor="light"
                textColor="light"
                text="Sign up for free"
                onClick={() => navigate("/sign-up")}
                noBorder
              />
            </Column>
          </StandoutCard>
        </Column>
        <Column style={{ flex: "1 0 50%" }}>
          <StyledImg />
        </Column>
      </Columns>
    </PaddedSection>
  );
};

const HowItWorksSection = (): JSX.Element => {
  const { theme } = useTheme();

  return (
    <PaddedSection>
      <Row>
        <StyledSmallStandOut theme={theme} color="dark">
          <Heading text="How it works" type="l" />
        </StyledSmallStandOut>
      </Row>
      <Spacer size="1em" />
      <DetailCardContainer>
        <DetailCard color={theme.colors.light} basis="25%">
          <SquarePen size="3em" color={theme.colors.warning} />
          <DetailHeading text="Write Flashcards Quickly and Easily" type="m" />
          <Paragraph>
            Use Pohtia’s intuitive web interface to create flashcards with{" "}
            <Callout>code snippets</Callout>, <Callout>LaTeX</Callout> for math
            and science, <Callout>embedded images</Callout>, and{" "}
            <Callout>Markdown formatting</Callout> all in one seamless workflow.
          </Paragraph>
        </DetailCard>

        <DetailCard color={theme.colors.light} basis="25%">
          <Sparkles size="3em" color={theme.colors.moderate} />
          <DetailHeading text="Gamify Your Learning Experience" type="m" />
          <Paragraph>
            Test your knowledge and stay motivated with Pohtia’s{" "}
            <Callout>engaging self-testing tools and XP system</Callout>,
            designed to make studying fun and effective.
          </Paragraph>
        </DetailCard>

        <DetailCard color={theme.colors.light} basis="25%">
          <Brain size="3em" color={theme.colors.easy} />
          <DetailHeading text="Turn Notes into Knowledge" type="m" />
          <Paragraph>
            Consistent note-taking and self-study are the simplest ways to move
            complex information into your <Callout>long-term memory</Callout>.
            Pohtia makes it effortless.
          </Paragraph>
        </DetailCard>
      </DetailCardContainer>
    </PaddedSection>
  );
};

const DetailsSection = (): JSX.Element => {
  const { theme } = useTheme();
  return (
    <PaddedSection>
      <StyledSmallStandOut theme={theme} color="dark">
        <Heading text="Features" type="l" />
      </StyledSmallStandOut>
      <Spacer size="0.5em" />
      <DetailCardContainer>
        <DetailCard color={theme.colors.light} basis="20%">
          <Heading1 size="3em" color={theme.colors.easy} />
          <DetailHeading text="Markdown Support" type="m" />
          <Paragraph>
            Craft and organize your study notes with full Markdown support. From
            bullet points to bold highlights, format your thoughts with ease.
          </Paragraph>
        </DetailCard>

        <DetailCard color={theme.colors.light} basis="20%">
          <Sigma size="3em" color={theme.colors.moderate} />
          <DetailHeading text="LaTeX Support" type="m" />
          <Paragraph>
            Ace complex equations and scientific notation using full LaTeX
            support, perfect for math, science, and technical studies.
          </Paragraph>
        </DetailCard>
        <DetailCard color={theme.colors.light} basis="20%">
          <Braces size="3em" color={theme.colors.warning} />
          <DetailHeading text="Code Support" type="m" />
          <Paragraph>
            Share and showcase your programming prowess with full code syntax
            support, making technical concepts a breeze to revisit.
          </Paragraph>
        </DetailCard>
        <DetailCard color={theme.colors.light} basis="20%">
          <ImageIcon size="3em" color={theme.colors.difficult} />
          <DetailHeading text="Image Integration" type="m" />
          <Paragraph>
            Copy-paste images directly into your notes. We’ll handle the
            hosting, so you can focus on studying, not file management.
          </Paragraph>
        </DetailCard>
      </DetailCardContainer>
    </PaddedSection>
  );
};

const Home = () => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to="/stacks" replace={true} />;
  }
  return (
    <>
      <HeroSection />
      <HowItWorksSection />
      <TryItExample />
      <DetailsSection />
    </>
  );
};

export default Home;
