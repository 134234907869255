import styled from "styled-components";
import { Action, Navigation, PageProps } from "./types";
import Spacer from "../components/Spacer";
import { Row } from "../components/layout";
import CommandButton from "../components/CommandButton";
import { useTheme } from "../contexts/ThemeContext";
import { To, useNavigate } from "react-router-dom";

const StyledContainer = styled.div<{ backgroundImage?: string }>`
  padding: 5vh 3vw;
  height: 100vh;
  ${(props) => {
    if (props.backgroundImage) {
      return `background-clip: padding-box;
  background-image: url(${props.backgroundImage});
  background-size: cover;
  background-position: center;`;
    }
  }};
`;

const NavigateActions = ({ navigation }: { navigation: Navigation[] }) => {
  const navigate = useNavigate();
  return (
    <Row style={{ gap: "5em" }}>
      {navigation.map((n) => (
        <CommandButton
          command={n.command || "back"}
          text={n.title}
          onClick={() => navigate(n.to as To)}
        />
      ))}
    </Row>
  );
};

const Actions = ({ actions }: { actions: Action[] }) => {
  return (
    <Row style={{ gap: "5em" }}>
      {actions.map((a) => (
        <CommandButton
          command={a.command || "add"}
          text={a.title}
          onClick={() => a.onClick()}
        />
      ))}
    </Row>
  );
};

const PaddedPage = ({
  children,
  actions,
  navigation,
  backgroundImage,
}: PageProps) => {
  const { theme } = useTheme();

  return (
    <StyledContainer backgroundImage={backgroundImage}>
      {navigation && navigation.length > 0 ? (
        <>
          <NavigateActions navigation={navigation} />
          <Spacer size={theme.spacing.paragraphSpacing.large} />
        </>
      ) : null}
      {actions && actions?.length > 0 ? (
        <>
          <Spacer size={theme.spacing.paragraphSpacing.normal} />
          <Actions actions={actions} />
          <Spacer size={theme.spacing.paragraphSpacing.large} />
        </>
      ) : null}
      {children}
    </StyledContainer>
  );
};

export default PaddedPage;
