import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import LabeledInput from "../components/LabeledInput";
import { Button } from "../components";
import NamedForm from "../components/NamedForm/NamedForm";
import StandoutCard from "../components/StandoutCard";
import GoogleLogo from "../google-icon-logo-svg-vector.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { oauthlLoginUser } from "../api";
import Spacer from "../components/Spacer";
import { toast } from "react-toastify";
import Callout from "../components/Callout/Callout";
import PaddedPage from "../layouts/PaddedPage";
import styled from "styled-components";
import { MOBILE_MAX_WIDTH } from "../global";

interface LoginFormData {
  email: string;
  password: string;
}

const LoginCard = styled(StandoutCard)`
  justify-self: center;
  min-width: 40vw;

  @media (max-width: ${MOBILE_MAX_WIDTH}px) {
    min-width: 80%;
  }
`;

const Login = (): JSX.Element => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>("");

  const { login, setIsAuthenticated, setUserId, setIsVerified } = useAuth();
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    try {
      const success = await login(formData.email, formData.password);
      if (success) {
        navigate("/");
      } else {
        setError("Incorrect email or password.");
      }
    } catch (error) {
      toast("Error with login.", { type: "error" });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (res) => {
      const { status, data } = await oauthlLoginUser(res.access_token);
      if (status === 200) {
        setIsAuthenticated(true);
        setUserId(String(data.user_id));
        setIsVerified(true);

        navigate("/");
      } else {
        setError("Google login failed.");
      }
    },
    onError: (error) => setError(`Google login failed: ${error}`),
  });

  return (
    <PaddedPage backgroundImage="clutter.png">
      <LoginCard>
        <NamedForm text="Login" onSubmit={handleSubmit}>
          <NamedForm.Row>
            <Button
              icon={GoogleLogo}
              type="button"
              text="Login with Google"
              onClick={() => googleLogin()}
              padding="s"
            />
          </NamedForm.Row>
          <Spacer withLine size="1em" />

          <NamedForm.Row>
            <LabeledInput
              text="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </NamedForm.Row>

          <NamedForm.Row>
            <LabeledInput
              text="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </NamedForm.Row>
          <NamedForm.Row>
            {error && <Callout type="error">{error}</Callout>}
          </NamedForm.Row>

          <NamedForm.Row>
            <Button type="submit" text="Login" onClick={() => {}} padding="s" />
          </NamedForm.Row>

          <NamedForm.Row style={{ maxWidth: "50%", alignSelf: "center" }}>
            <Button
              type="submit"
              text="Forgot password"
              onClick={() => {
                navigate("/forgot-password");
              }}
              btnStyle="light"
              padding="xs"
            />
          </NamedForm.Row>
        </NamedForm>
      </LoginCard>
    </PaddedPage>
  );
};

export default Login;
