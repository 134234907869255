import { CodeProps } from "react-markdown/lib/ast-to-react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNightBright as highlightStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Theme, useTheme } from "../../contexts/ThemeContext";
import styled from "styled-components";

const LANGUAGE_ALIASES: { [k in string]: string } = {
  js: "javascript",
  py: "python",
};

const StyledInline = styled.code<{ theme: Theme }>`
  font-family: "Courier New", Courier, monospace;
  background-color: ${({ theme }) => theme.colors.muted};
  color: ${({ theme }) => theme.colors.light};
  padding: 0.25em 0.45em;
  border-radius: 0.25em;
  font-weight: ${({ theme }) => theme.type.bold};
`;

const Code = ({ inline, children, className }: CodeProps) => {
  const { theme } = useTheme();

  if (inline) {
    return (
      <StyledInline theme={theme}>{children.join("").trim()}</StyledInline>
    );
  }
  const hasLang = /language-(\w+)/.exec(className || "");

  let language = "plaintext";

  if (hasLang) {
    const lang = hasLang[1];
    const alias = LANGUAGE_ALIASES[lang];
    language = alias ? alias : lang;
  }

  return (
    <SyntaxHighlighter
      customStyle={{
        background: theme.colors.muted,
        borderRadius: "0.25em",
        padding: theme.spacing.padding.m,
        lineHeight: theme.spacing.lineHeight.normal,
        fontFamily: "'Courier New', Courier, monospace",
      }}
      language={language}
      style={highlightStyle}
      wrapLines
    >
      {children.join("").trim()}
    </SyntaxHighlighter>
  );
};

export default Code;
