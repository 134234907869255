import { HTMLAttributes } from "react";
import styled from "styled-components";
import { useTheme, Theme } from "../../contexts/ThemeContext";

const StyledBlock = styled.div<{ theme: Theme }>`
  font-size: 1.3em;
  margin: 1em;
`;

const LatexBlock = (props: HTMLAttributes<HTMLDivElement>) => {
  const { theme } = useTheme();
  return <StyledBlock theme={theme} {...props} />;
};

export default LatexBlock;
