import styled from "styled-components";
import { Theme, useTheme } from "../../contexts/ThemeContext";

interface Props {
  children: React.ReactNode;
  type?: "bold" | "italic" | "error";
}

const StyledCallout = styled.span<{
  type: "bold" | "italic" | "error";
  theme: Theme;
}>`
  ${({ type, theme }) => {
    if (type === "bold") {
      return `font-weight: ${theme.type.bold}`;
    }

    if (type === "italic") {
      return `font-style: italic`;
    }

    if (type === "error") {
      return `color: ${theme.colors.warning}`;
    }
  }}
`;

const Callout = ({ children, type, ...props }: Props): JSX.Element => {
  const { theme } = useTheme();
  return (
    <StyledCallout theme={theme} type={type || "bold"} {...props}>
      {children}
    </StyledCallout>
  );
};

export default Callout;
