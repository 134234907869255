import { useCallback, useEffect, useRef, useState } from "react";
import { Column } from "../layout";
import { Theme, useTheme } from "../../contexts/ThemeContext";
import styled, { css } from "styled-components";

type ChildType = JSX.Element | boolean;

interface Props {
  children: JSX.Element | ChildType[];
}

const HamburgerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
`;

const lineStyle = css`
  width: 25px;
  height: 3px;
  background: #000;
  transition: all 0.3s;
`;

const Line1 = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})<{ isOpen: boolean }>`
  ${lineStyle}
  transform: ${(props) =>
    props.isOpen ? "rotate(45deg) translate(5px, 6px)" : "none"};
`;

const Line2 = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})<{ isOpen: boolean }>`
  ${lineStyle}
  opacity: ${(props) => (props.isOpen ? 0 : 1)};
`;

const Line3 = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})<{ isOpen: boolean }>`
  ${lineStyle}
  transform: ${(props) =>
    props.isOpen ? "rotate(-45deg) translate(5px, -6px)" : "none"};
`;

const MenuTray = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})<{ isOpen: boolean; theme: Theme }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 75vw;
  height: 100vh;
  padding-top: 5vh;
  padding-left: 5vw;
  background: ${(props) => props.theme.colors.warning};
  color: ${(props) => props.theme.colors.light};
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 10000;
  font-size: ${(props) => props.theme.fontSize.heading} !important;
`;

const HamburgerMenu = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const trayRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target;
    if (menuRef.current?.contains(target as Node)) {
      return;
    } else if (target !== trayRef.current) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div id="wrapper">
      <Column ref={menuRef}>
        <HamburgerWrapper id="hambuger" onClick={toggleMenu}>
          <Line1 isOpen={isOpen} />
          <Line2 isOpen={isOpen} />
          <Line3 isOpen={isOpen} />
        </HamburgerWrapper>
      </Column>
      <MenuTray isOpen={isOpen} theme={theme} ref={trayRef}>
        <Column style={{ gap: "1.5em" }}>{props.children}</Column>
      </MenuTray>
    </div>
  );
};

export default HamburgerMenu;
