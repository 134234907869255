import styled from "styled-components";
import { NamedColor, Theme, useTheme } from "../contexts/ThemeContext";
import { bool } from "aws-sdk/clients/signer";
import { HTMLAttributes } from "react";
import { LucideIcon } from "lucide-react";

type BtnVariant = "block" | "outline";
type Padding = "xs" | "s" | "m" | "l" | "xl";

interface ButtonProps {
  text: string;
  icon: LucideIcon;
  iconColor?: NamedColor;
  color?: NamedColor;
  textColor?: NamedColor;
  padding?: Padding;
  noShadow?: bool;
  variant?: BtnVariant;
  disabled?: boolean;
  noBorder?: boolean;
}

const StyledButton = styled.button<{
  theme: Theme;
  disabled: boolean;
  color: NamedColor;
  textColor: NamedColor;
  padding?: Padding;
  noBorder?: boolean;
}>`
  display: flex;
  flex: 1 1 auto;
  min-width: 120px;
  border: none;
  background: none;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.body};
  font-weight: ${(props) => props.theme.type.regular};
  text-decoration: none;
  padding: ${({ theme, padding }) =>
    padding ? theme.spacing.padding[padding] : theme.spacing.padding.xs};
  border: ${({ noBorder, theme }) =>
    noBorder ? "none" : `2px solid ${theme.colors.dark}`};
  border-radius: 50px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  box-shadow: 6px 6px 2px 1px rgba(0, 0, 0, 0.2);
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  background-color: ${({ theme, color }) => theme.colors[color]};
  ${({ disabled, theme }) =>
    !disabled &&
    `&:hover {
    background-color: ${theme.colors.dark};
    color: ${theme.colors.light};`}
  }
`;

const IconContainer = styled.span<{
  theme: Theme;
  color?: NamedColor;
  disabled: boolean;
}>`
  display: flex;
  justify-content: center;
  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.dark};
  opacity: ${({ disabled }) => (!disabled ? "1.0" : "0.3")};
  margin: 0 0.5em;

  transition: color 0.2s ease-in-out;

  ${StyledButton}:hover & {
    ${({ theme, disabled }) => !disabled && `color: ${theme.colors.light};`}
  }
`;

const TextContainer = styled.span<{ theme: Theme }>`
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

const IconButton = ({
  text,
  icon: Icon,
  children,
  padding,
  variant = "outline",
  color = "light",
  noShadow = false,
  iconColor = "dark",
  textColor = "dark",
  disabled = false,
  ...props
}: ButtonProps & HTMLAttributes<HTMLButtonElement>) => {
  const { theme } = useTheme();

  return (
    <StyledButton
      theme={theme}
      disabled={disabled}
      color={color}
      textColor={textColor}
      padding={padding}
      {...props}
    >
      <IconContainer theme={theme} disabled={disabled} color={iconColor}>
        <Icon size={22} />
      </IconContainer>
      <TextContainer theme={theme}>{text}</TextContainer>
    </StyledButton>
  );
};

export default IconButton;
