import { useEffect, useState } from "react";
import { Stack } from "../types";
import { createStack, getAllStacks, updateStack } from "../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../components/Modal";
import { useAuth } from "../contexts/AuthContext";
import NamedForm from "../components/NamedForm/NamedForm";
import LabeledInput from "../components/LabeledInput";
import StandoutCard from "../components/StandoutCard";
import Spacer from "../components/Spacer";
import { Button } from "../components";
import { Column, Grid, Row } from "../components/layout";
import TitledPage from "../layouts/TitledPage";
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";
import PaddedPage from "../layouts/PaddedPage";
import { BookOpen, Eye } from "lucide-react";
import IconButton from "../components/IconButton";

const NameModal = ({
  isOpen,
  onClose,
  onSave,
  stack,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (id: number | undefined, name: string) => void;
  stack?: Stack;
}) => {
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (stack) {
      setName(stack.name);
    }
  }, [stack]);

  const handleSave = (_e: React.FormEvent<HTMLFormElement>) => {
    onSave(stack?.id, name);
    setName("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Body>
        <NamedForm
          id="new-stack-form"
          text={stack ? "Edit Stack" : "Create a new stack"}
          onSubmit={handleSave}
        >
          <LabeledInput
            text="Stack name"
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </NamedForm>
      </Modal.Body>
      <Modal.Footer>
        <Button text="Cancel" onClick={onClose} noShadow padding="xs" />
        <Button
          text="Confirm"
          type="submit"
          form="new-stack-form"
          padding="xs"
          onClick={() => {}}
          noShadow
        />
      </Modal.Footer>
    </Modal>
  );
};

const StackCard = ({ stack }: { stack: Stack }) => {
  const navigate = useNavigate();

  return (
    <StandoutCard
      border
      style={{
        flexDirection: "column",
      }}
    >
      <Heading text={stack.name} style={{ wordBreak: "break-word" }} />
      <Paragraph>{`${stack.number_of_questions} question(s)`}</Paragraph>

      <Row style={{ gap: "1em", justifyContent: "flex-end" }}>
        <IconButton
          icon={BookOpen}
          iconColor="moderate"
          disabled={stack.number_of_questions === 0}
          onClick={() =>
            navigate(`/stacks/${stack.id}/study`, {
              state: { stackName: stack.name },
            })
          }
          text="Study"
        />

        <IconButton
          icon={Eye}
          iconColor="difficult"
          onClick={() => navigate(`/stacks/${stack.id}`)}
          variant="outline"
          text="View"
        />
      </Row>
    </StandoutCard>
  );
};

const Stacks = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [stacks, setStacks] = useState<Stack[]>([]);
  const { logout, isVerified } = useAuth();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const pageParam = params.get("page");
  const page = Number(pageParam);

  useEffect(() => {
    (async () => {
      try {
        if (page < 0) {
          navigate("/stacks");
          return;
        }

        const {
          data: { list: respStacks, cursors: newCursors },
          status,
        } = await getAllStacks(page);

        if (status >= 200 && status < 400) {
          setStacks(respStacks);
          setLoading(false);
        } else {
          logout();
        }
      } catch (e) {
        logout();
      }
    })();
  }, [logout, navigate, page, params]);

  if (!isVerified) {
    return (
      <PaddedPage>
        <StandoutCard
          border
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Column>
            <Heading text="📣 Check your email" type="xl" />
            <Spacer withLine size="3em" lineStyle="dashed" />
            <Paragraph>
              You must verify your email before you can access your Pohtia
              account!
            </Paragraph>
          </Column>
        </StandoutCard>
      </PaddedPage>
    );
  }

  return (
    <TitledPage
      title="Your Card Stacks"
      loading={loading}
      actions={[
        {
          title: "Add a stack",
          onClick: () => setShowModal(true),
          color: "easy",
        },
      ]}
      pagination={{
        prevDisabled: page <= 0,
        nextDisabled: stacks.length < 12,
        onNext: () => {
          navigate(`/stacks?page=${page ? page + 1 : 1}`);
        },
        onPrev: () => {
          navigate(`/stacks?page=${page ? page - 1 : 0}`);
        },
      }}
    >
      <Grid columns="repeat(auto-fill, minmax(320px, 1fr))">
        {stacks.length > 0 ? (
          stacks.map((s) => <StackCard stack={s} />)
        ) : (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              fontStyle: "italic",
              gridColumn: "1 / -1",
              maxWidth: "100%",
            }}
          >
            <Heading text="Add some stacks" type="l" />
          </Row>
        )}
      </Grid>
      <NameModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onSave={async (stackId: number | undefined, stackName: string) => {
          if (stackId) {
            const { status, data } = await updateStack(
              String(stackId),
              stackName
            );

            if (status === 200) {
              const newStacks = stacks.map((s) => {
                if (s.id === stackId) {
                  return data;
                }

                return s;
              });
              setStacks(newStacks);
              setShowModal(false);
            }
          } else {
            const { status, data } = await createStack(stackName);

            if (!data.number_of_questions) {
              data.number_of_questions = 0;
            }

            if (status === 200) {
              setStacks([...stacks, data]);
              setShowModal(false);
            }
          }
        }}
      />
    </TitledPage>
  );
};

export default Stacks;
