import { HTMLAttributes } from "react";
import { styled } from "styled-components";
import { Theme, useTheme } from "../../contexts/ThemeContext";

const StyledLI = styled.li<{ theme: Theme }>`
  &::marker {
    font-weight: ${({ theme }) => theme.type.semiBold};
    line-height: ${({ theme }) => theme.spacing.lineHeight.loose};
  }
`;

const Li = (props: HTMLAttributes<HTMLLIElement>) => {
  const { theme } = useTheme();
  return <StyledLI theme={theme} {...props} />;
};

export default Li;
