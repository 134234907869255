import { deleteAccount } from "../api";
import { User } from "../types";
import LabeledValue from "../components/LabeledValue";
import Spacer from "../components/Spacer";
import { useAuth } from "../contexts/AuthContext";
import StandoutCard from "../components/StandoutCard";
import LoadingSpinner from "../components/LoadingSpinner";
import TitledPage from "../layouts/TitledPage";
import { Row } from "../components/layout";
import { useLoaderData, useNavigate } from "react-router-dom";
import IconButton from "../components/IconButton";
import { DoorOpen, Trash2 } from "lucide-react";
import { toast } from "react-toastify";

const calcLevel = (xp: number): number => {
  if (xp < 1000) {
    return 1;
  }

  return Math.floor(xp / 1000);
};

const MyAccount = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { user } = useLoaderData() as { user: User };

  const handleAccountDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete your account?");

    if (!confirmed || !user) return;

    const { status } = await deleteAccount(String(user.id));

    if (status === 200) {
      logout();
    }
  };

  if (!user) return <LoadingSpinner />;

  return (
    <TitledPage title="My Account">
      <StandoutCard border>
        <LabeledValue text="Level" value={calcLevel(user.xp)} />
        <LabeledValue text="XP" value={user.xp} />
        <Spacer withLine size="1em" />
        <LabeledValue text="Email" value={user.email} />
        <Spacer size="2em" />
        <Row style={{ justifyContent: "space-between", gap: "1.5em" }}>
          <IconButton
            icon={Trash2}
            iconColor="warning"
            text="Delete account"
            onClick={handleAccountDelete}
            style={{ flex: "0 0 25%" }}
          />
          <IconButton
            icon={DoorOpen}
            iconColor="moderate"
            text="Log out"
            onClick={async () => {
              await logout();
              toast("Logged out");
              navigate("/");
            }}
            style={{ flex: "0 0 25%" }}
          />
        </Row>
      </StandoutCard>
    </TitledPage>
  );
};

export default MyAccount;
